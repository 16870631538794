<template>
  <v-app class="auth">
    <layout-header class="auth__layout-header" />

    <v-dialog v-model="dialogPasswordChanged" max-width="390">
      <v-card>
        <v-card-title class="text-h7">
          {{ $t('dialog.password_changed') }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text to="/auth/login" @click="dialogPasswordChanged = false">
            {{ $t('button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEmailSend" max-width="390">
      <v-card>
        <v-card-title class="text-h7">
          {{ $t('dialog.email_sended') }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text to="/auth/login" @click="dialogEmailSend = false">
            {{ $t('button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogTokenNotValid" max-width="390">
      <v-card>
        <v-card-title class="text-h7">
          {{ $t('dialog.link_not_valid') }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text to="/auth/login" @click="dialogTokenNotValid = false">
            {{ $t('button.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main class="auth__main">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <div v-if="isShow">
              <div v-if="hasValidToken" class="login">
                <img src="@/assets/img/argo-logo-without-text.svg" alt width="92" height="27" />
                <h1 class="mb-10">{{ $t('login.password_update') }}</h1>

                <!-- @todo исправить для i18n после правки бэка -->
                <div v-if="error" class="error--text mb-4">{{ error }}</div>

                <update-password-form @submit="updatePassword" />
              </div>

              <div v-else class="login">
                <img src="@/assets/img/argo-logo-without-text.svg" alt width="92" height="27" />
                <h1 class="mb-10">{{ $t('login.password_recovery') }}</h1>

                <!-- @todo исправить для i18n после правки бэка -->
                <div v-if="error" class="error--text mb-4">{{ error }}</div>

                <reset-password-form @submit="sendEmail" />
              </div>
            </div>

            <div v-else class="app__loader" style="height: auto">
              <v-progress-circular indeterminate />
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>

    <layout-footer />
  </v-app>
</template>

<script>
// Constants
import { RESET_PASSWORD_PAGE } from '@/constants/analyticsActions';

// Services
import analyticsService from '@/services/analytics';
import { sendEmail, checkToken, updatePassword } from '@/services/resetPassword';
// Components
import UpdatePasswordForm from '@/components/UpdatePasswordForm.vue';
import ResetPasswordForm from '@/components/ResetPasswordForm.vue';
import LayoutHeader from '@/components/layout/Header/index.vue';
import LayoutFooter from '@/components/layout/Footer.vue';

const ANALYTICS_DELAY = 10000;

export default {
  name: 'Login',

  components: {
    UpdatePasswordForm,
    ResetPasswordForm,
    LayoutHeader,
    LayoutFooter,
  },

  data: () => ({
    error: '',
    dialogPasswordChanged: false,
    dialogEmailSend: false,
    dialogTokenNotValid: false,
    hasValidToken: false,
    isShow: false,
    userId: null,
    token: null,
  }),

  watch: {
    dialogTokenNotValid() {
      if (!this.dialogTokenNotValid) {
        this.$router.push('/auth/login');
      }
    },

    dialogPasswordChanged() {
      if (!this.dialogPasswordChanged) {
        this.$router.push('/auth/login');
      }
    },
  },

  async created() {
    const params = new URL(document.location).searchParams;
    this.token = params.get('token');
    if (this.token) {
      this.checkToken(this.token);
    } else {
      this.isShow = true;
    }
  },

  async mounted() {
    this.$options.analyticsTimer = setTimeout(() => {
      analyticsService.track(RESET_PASSWORD_PAGE);
    }, ANALYTICS_DELAY);
  },

  beforeDestroy() {
    clearTimeout(this.$options.analyticsTimer);
  },

  methods: {
    async sendEmail({ email }) {
      const response = await sendEmail(email);
      if (response) this.dialogEmailSend = true;
    },

    async checkToken(token) {
      this.userId = await checkToken(token);
      this.isShow = true;
      if (this.userId) this.hasValidToken = true;
      if (!this.hasValidToken) this.dialogTokenNotValid = true;
    },

    async updatePassword(form) {
      const response = await updatePassword(this.userId, {
        data: this.token,
        password1: form.password,
        password2: form.passwordСonfirmation,
      });
      if (response) {
        this.dialogPasswordChanged = true;
      } else {
        this.dialogTokenNotValid = true;
      }
    },
  },

  analyticsTimer: null,
};
</script>

<style lang="scss">
.app {
  .auth {
    &__layout-header {
      @media (min-width: map.get($--screens, 'sm')) {
        padding-left: 64px !important;
        padding-right: 64px !important;
      }

      @media (min-width: map.get($--screens, 'md')) {
        padding-left: 160px !important;
        padding-right: 160px !important;
      }
    }

    &__main {
      padding-top: 80px !important;

      @media (min-width: map.get($--screens, 'sm')) {
        padding-top: 110px !important;
      }
    }
  }
}
</style>
