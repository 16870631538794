<template>
  <v-app-bar app class="layout-header">
    <v-container class="layout-header__container d-flex align-center pa-0 px-3 px-md-0" fluid>
      <router-link class="layout-header__logo-link" :to="homeLink">
        <img :src="logoURI" alt="argo" width="156" height="22" class="layout-header__logo" />
      </router-link>

      <v-spacer />

      <layout-header-navigation v-if="hasRoutes" :routes="routes" @update:is-trim="updateIsTrim" />

      <layout-header-language v-if="!languagesListLoading" v-model="languageModel" :languages="languagesList" />

      <layout-header-profile v-if="hasProfile" />
    </v-container>
  </v-app-bar>
</template>

<script>
// node_modules
import { mapGetters, mapActions } from 'vuex';

// Services
import companyService from '@/services/company';
import analyticsService from '@/services/analytics';

// Constants
import { LOGIN, MAIN } from '@/constants/routes';
import { CHANGE_LANGUAGE } from '@/constants/analyticsActions';

// Components
import LayoutHeaderLanguage from './Language.vue';
import LayoutHeaderProfile from './Profile.vue';
import LayoutHeaderNavigation from './Navigation.vue';

export default {
  name: 'LayoutHeader',

  components: { LayoutHeaderLanguage, LayoutHeaderProfile, LayoutHeaderNavigation },

  props: {
    hasProfile: { type: Boolean, default: false },
    routes: { type: Array, default: () => [] },
  },

  inject: ['media'],

  data: () => ({
    logoURI: '',
  }),

  computed: {
    ...mapGetters(['selectedLanguage', 'languagesList', 'languagesListLoading']),

    hasRoutes() {
      return this.routes.length > 0;
    },

    languageModel: {
      get() {
        return this.selectedLanguage;
      },

      set(language) {
        analyticsService.track(CHANGE_LANGUAGE, analyticsService.createChangeLanguagePayload(language.code));
        this.selectLanguage(language);
      },
    },

    homeLink() {
      if (this.hasProfile) {
        return { name: MAIN };
      }

      return { name: LOGIN };
    },
  },

  async mounted() {
    this.logoURI = await companyService.getLogoUri();
  },

  methods: {
    ...mapActions(['selectLanguage']),

    updateIsTrim(value) {
      this.$emit('update:is-trim', value);
    },
  },
};
</script>

<style lang="scss">
.layout-header {
  z-index: 9 !important;

  height: 80px !important;

  @media (max-width: map.get($--screens, 'sm')) {
    height: 56px !important;
  }

  .v-toolbar__content {
    height: 80px !important;

    @media (max-width: map.get($--screens, 'sm')) {
      height: 56px !important;
    }
  }

  .router-link-active {
    cursor: default;
  }

  &__logo-link {
    display: flex;
  }

  &__logo {
    object-fit: cover;
  }

  &__container {
    height: 100%;
  }

  .v-toolbar__content {
    padding: 0;
  }
}
</style>
